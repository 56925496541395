import { useEffect, useMemo } from "react"
import { useState } from "react"
import { useLocation } from "react-router-dom"
import styled from "styled-components"
import { statusColors } from "../../../core/utils/theme"
import Text from "../../../core/utils/Text"
import countries from "../../../core/constants/countries.json"
import { FaEye } from "react-icons/fa6";
import { IconWrapper } from "../../../styles/CommonStyles"
import ActionButton from "../../../components/common/buttons/ActionButton"
import { useQueryContext } from "../../../core/contexts/query"
import { useUpdateUserKyc } from "../../../core/services/react-query/profile"


const ProfileInfo = ({
    detail = false
}) => {

    const { state } = useLocation()

    const [kycStatus, setKycStatus] = useState('created')
    const [processedData, setProcessedData] = useState({})

    useEffect(() => {
        if (!!state?.user?.id) {
            setKycStatus(state?.user.is_kyc_passed)
            setProcessedData(state?.user.kyc)
        }
    }, [state])

    const [processInputKeys] = useState([
        'first_name', 'last_name',
        'father_name', 'gender',
        'birthday', 'nationality',
        'address', 'passport_number'
    ])

    const changeStatus = (id, value) => {
        setProcessedData(state => ({ ...state, [id]: value }))
    }

    const [showImage, setShowImage] = useState('')

    const validAction = useMemo(() => {
        const list = Object.keys(processedData)
        for (let i = 0; i < list.length; i++) {
            if (processedData[list[i]] === 'not processed' || !processedData[list[i]]) {
                return false
            }
        }
        return true
    }, [processedData])


    const { mutate: updateKyc, isLoading } = useUpdateUserKyc()

    const { setToast } = useQueryContext()
    const onSubmitKyc = () => {
        if (!validAction) {
            setToast({ message: 'accept-reject-all-items', isError: true, show: true })
            return
        }
        if (isLoading) return
        updateKyc({
            ...processedData,
            user_id: state.user.id
        })
    }

    const onAcceptAll = () => {
        let temp = {}
        Object.keys(processedData).forEach(k => {
            temp[k] = 'OK'
        })
        setProcessedData(temp)
    }

    const onRejectAll = () => {
        let temp = {}
        Object.keys(processedData).forEach(k => {
            temp[k] = 'NOK'
        })
        setProcessedData(temp)
    }

    return (
        <div className={'flex flex-col w-full gap-12  p-12'}>
            <div className={'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 w-full'}>

                {processInputKeys?.map(k => {

                    return (
                        <ProcessInput
                            key={k}
                            id={k}
                            value={state?.user[k] || ''}
                            status={processedData[k] || 'not processed'}
                            changeStatus={changeStatus}
                            detail={detail}
                        />
                    )
                })}
            </div>

            <div className={'grid md:grid-cols-2 w-full'}>

                {['original_passport', 'personal_photo'].map(k => {

                    return (
                        <div className={'flex items-center justify-center'}>
                            <ProcessInputWrapper className={`
                        w-[240px] h-[300px] rounded-md dark:text-gray-300
                    `}
                                statusColor={statusColors[processedData[k] || 'not processed']}
                            >
                                {!!state?.user?.[k] ?
                                    <img
                                        src={state?.user?.[k]?.url}
                                        className={'w-[240px] h-[240px] rounded-md'}
                                        alt={'personal-ph'}
                                    />
                                    :
                                    <div className={'w-[240px] h-[240px] flex items-center justify-center'}>
                                        <Text tid={'no-image-uploaded'} />
                                    </div>
                                }
                                <div className={'flex items-center justify-between px-5 py-2'}>
                                    <IconWrapper onClick={() => setShowImage(k)}>
                                        <FaEye size={24} />
                                    </IconWrapper>
                                    {detail ?
                                        <AcceptRejectBtns
                                            status={processedData[k] || 'not processed'}
                                            onAcceptClick={() => changeStatus(k, 'OK')}
                                            onRejectClick={() => changeStatus(k, 'NOK')}
                                        />
                                        :
                                        processedData['personal_photo'] || 'not processed'
                                    }
                                </div>
                            </ProcessInputWrapper>
                        </div>
                    )
                })}
            </div>

            <div className={'flex items-center justify-center gap-8'}>
                <div
                    onClick={onAcceptAll}
                    className={`
                            w-[144px] rounded-md flex items-center justify-center 
                            h-[42px] border-[1px] border-slate-500 border-opacity-50
                            cursor-pointer bg-green-500 bg-opacity-50 text-gray-200
                        `}>
                    <Text tid={'accept-all'} className={'text-sm'} />
                </div>
                <div
                    onClick={onRejectAll}
                    className={`
                            w-[144px] rounded-md flex items-center justify-center 
                            h-[42px] border-[1px] border-slate-500 border-opacity-50
                            cursor-pointer bg-red-500 bg-opacity-50 text-gray-200
                        `}>
                    <Text tid={'reject-all'} className={'text-sm'} />
                </div>
                <ActionButton
                    active={validAction}
                    width={'300px'}
                    height={'42px'}
                    onClick={onSubmitKyc}
                    loading={isLoading}
                >
                    <Text tid={'submit-kyc'} />
                </ActionButton>
            </div>

            {showImage ?
                <ShowImageFullScreen
                    image={state?.user?.[showImage]}
                    showImage={showImage}
                    onClose={() => setShowImage('')}
                />
                : null}
        </div>
    )
}

const ProcessInput = ({ id, value, status, detail, changeStatus, ...rest }) => {

    const formattedValue = useMemo(() => {
        if (id === 'birthday') return (new Date(value * 1000)).toLocaleDateString()
        if (id === 'nationality') return countries.find(x => x.code === value)?.name || value

        return value
    }, [id, value])

    const onAcceptClick = () => {
        changeStatus(id, 'OK')
    }

    const onRejectClick = () => {
        changeStatus(id, 'NOK')
    }

    return (
        <div className={'flex flex-col gap-1 dark:text-gray-200'}>
            <Text tid={id} className={'text-sm'} />
            <ProcessInputWrapper
                className={'px-4 py-2 rounded-md shadow min-w-[300px] min-h-[48px] flex items-center justify-between'}
                statusColor={statusColors[status]}
                {...rest}
            >
                <span>{formattedValue}</span>
                {detail ?
                    <AcceptRejectBtns
                        status={status}
                        onAcceptClick={onAcceptClick}
                        onRejectClick={onRejectClick}
                    />
                    : null}
            </ProcessInputWrapper>
        </div>
    )
}

const AcceptRejectBtns = ({ status, onAcceptClick, onRejectClick }) => {

    return (
        <div
            className={`
                h-[38px] w-[124px] flex gap-1
                `}
        >
            <div
                onClick={onAcceptClick}
                className={`
                            w-[72px] rounded-md flex items-center justify-center 
                            h-full border-[1px] border-slate-500 border-opacity-50
                            ${status === 'OK' ? 'bg-green-500' : ''} cursor-pointer
                        `}>
                <Text tid={'accept'} className={'text-sm'} />
            </div>
            <div
                onClick={onRejectClick}
                className={`
                            w-[72px] rounded-md flex items-center justify-center 
                            h-full border-[1px] border-slate-500 border-opacity-50
                            ${status === 'NOK' ? 'bg-red-500' : ''} cursor-pointer
                        `}>
                <Text tid={'reject'} className={'text-sm'} />
            </div>
        </div>
    )
}

const ShowImageFullScreen = ({ showImage, image, onClose }) => {

    if (!image) return <></>

    return (
        <div
            onClick={onClose}
            className={'fixed left-0 top-0 flex items-center justify-center z-[100000] w-screen h-screen backdrop-blur-md bg-white/30'}>
            <img
                src={image.url}
                className={'w-[600px]'}
                alt={showImage}
            />
        </div>
    )
}

const ProcessInputWrapper = styled.div`
    background-color: ${props => props.theme.mainBg};
    border: 1px solid ${props => props.statusColor}40;
`

export default ProfileInfo
