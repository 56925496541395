import { useMainContext } from "../../contexts/main";
import axios from "axios";
import { useEffect } from "react";

const useSetAxiosHeaders = () => {

    const { token, lang } = useMainContext()

    axios.defaults.headers['Authorization'] = `Bearer ${token}`
    axios.defaults.headers['Accept-Language'] = lang
}



export {
    useSetAxiosHeaders
}
