import { Column, TableBody, Row, Flex, TableWrapper } from "../../../styles/CommonStyles";
import Text from "../../../core/utils/Text";
import Pagination from "../../common/utils/Pagination";
import Skeleton from "../../../core/packages/skeleton";
import ListSkeleton from "../../skeleton/ListSkeleton";
import useTableLayout from "../../../core/hooks/layout/useTableLayout";
import NoData from "../../common/utils/NoData";
import SortButton from "../../common/buttons/SortButton";
import { useWindowSize } from "../../../core/hooks/common/useWindowSize";


const TableLayout = ({
    headers,
    cs,
    children,
    data,
    hasWriteAccess,
    hasPagination = true
}) => {

    const { data: _data, loading } = data
    const TOTAL = _data?.meta?.total ? _data?.meta.total : 0
    const { width } = useWindowSize()

    const {
        pagination,
        onPageChange,
        onLimitChange,
        onSortChange
    } = useTableLayout()

    const centerHeaders = [
        'date', 'cause', 'action', 'share',
        'is-active', 'is-available', 'traded-date',
        'permissions', 'file-name', 'file-type',
        'file-status', 'status', 'trace-type'
    ]

    const TableHead = () => {
        return (
            <Row cs={cs} header style={{ padding: '4px 20px' }}>
                <Column>
                    <Text tid={'index'} />
                </Column>
                {headers?.map(item => (
                    <Column
                        key={item}
                        center={centerHeaders.includes(item.title)}
                        style={{ cursor: item.sort && 'pointer' }}
                        onClick={() => {
                            item.sort && onSortChange(item.sort)
                        }}
                    >
                        <Flex>
                            <Text tid={item.title} />
                            {item.sort &&
                                <SortButton
                                    active={
                                        pagination?.sort === item.sort ?
                                            pagination?.order : false
                                    }
                                />
                            }
                        </Flex>
                    </Column>
                ))}
                {hasWriteAccess &&
                    <Column center />
                }
            </Row>
        )
    }

    return (
        <>
            {width > 1400 &&
                <TableHead />
            }
            <TableBody>
                <TableWrapper>
                    {width <= 1400 &&
                        <TableHead />
                    }
                    <div style={{ minHeight: '300px' }}>
                        {loading ?
                            <Skeleton
                                Content={ListSkeleton}
                                count={5}
                                params={{ cs, headers }}
                            />
                            :
                            (
                                (_data && !_data?.data?.list?.length) ?
                                    <NoData />
                                    :
                                    // <div style={{
                                    //     height: '54vh',
                                    //     overflowY: 'auto',
                                    //     overflowX: 'hidden',
                                    //     padding: '0 20px'
                                    // }}>
                                    children
                                // </div>
                            )
                        }
                    </div>
                </TableWrapper>
            </TableBody>
            {hasPagination &&
                <Pagination
                    total={TOTAL}
                    page={pagination?.page}
                    limit={pagination?.limit}
                    onPageChange={onPageChange}
                    onLimitChange={onLimitChange}
                />
            }
        </>

    )
}


export default TableLayout
