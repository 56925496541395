import { useMutation } from "react-query";
import { postApi } from "../../fetch-api/post";
import { useQueryContext } from "../../../contexts/query";
import { useNavigate } from "react-router-dom";
import { useMainContext } from "../../../contexts/main";
import { deleteApi } from "../../fetch-api/delete";
import useAsyncError from "../../../hooks/common/useAsyncError";
import { useAclContext } from "../../../contexts/acl";


const AUTH_KEYS = {
    REQUEST_OTP: 'request-otp',
    VERIFY_OTP: 'verify-otp',
    VERIFY_2FA: 'verify-2fa',
    LOGOUT: 'logout',
    neoLogin: 'neoLogin'
}


export const useNeoLogin = (onSuccess) => {

    const throwError = useAsyncError()
    return useMutation(
        AUTH_KEYS.neoLogin, (data) => postApi(data, 'login', throwError),
        {
            onSuccess,
        }
    )
}


const useRequestOtp = (onSuccess, onError) => {

    const throwError = useAsyncError()
    return useMutation(
        AUTH_KEYS.REQUEST_OTP, (data) => postApi(data, 'auth/otp', throwError),
        {
            onSuccess,
            onError
        }
    )
}


const useVerifyOtp = (onSuccess, onError) => {

    const navigate = useNavigate()
    const { queryClient } = useQueryContext()
    const { setToken } = useMainContext()
    const { setRole } = useAclContext()

    return useMutation(
        AUTH_KEYS.VERIFY_OTP, (data) => postApi(data, 'auth/otp/verify'),
        {
            onSuccess: (res) => {
                if (!res.data?.data?.authenticator) {
                    setToken(res.headers['x-auth-token'])
                    setRole(res?.data?.data?.user?.role)
                    navigate('/')
                    queryClient.invalidateQueries('fetch-profile')
                } else {
                    onSuccess()
                }
            },
            onError
        }
    )
}


const useVerify2fa = (onError) => {

    const navigate = useNavigate()
    const { queryClient } = useQueryContext()
    const { setToken } = useMainContext()
    const { setRole } = useAclContext()

    return useMutation(
        AUTH_KEYS.VERIFY_OTP, (data) => postApi(data, 'auth/2fa/verify'),
        {
            onSuccess: (res) => {
                setToken(res.headers['x-auth-token'])
                setRole(res?.data?.data?.user?.role)
                navigate('/')
                queryClient.invalidateQueries('fetch-profile')

            },
            onError
        }
    )
}


const useLogout = () => {

    const navigate = useNavigate()
    const { setToken } = useMainContext()
    const { setToast, queryCache } = useQueryContext()
    const { reset, queryClient } = useAclContext()

    return useMutation(
        AUTH_KEYS.LOGOUT, () => deleteApi('auth/token'),
        {
            onSuccess: () => {
                setToast({
                    show: true,
                    message: 'logout-success'
                })
                setToken('')
                reset()
                queryCache?.clear()
                navigate('/login')
                setTimeout(() => {
                    window.location.reload()
                }, 2000)
            }
        }
    )
}

export {
    useRequestOtp,
    useVerifyOtp,
    useVerify2fa,
    useLogout
}
