import TableLayout from "../../layout/main/TableLayout";
import { Column, DText, Flex, Relative, Row } from "../../../styles/CommonStyles";
import { formatName, formatNumber } from "../../../core/utils/common";
import { UserStatus } from "../../../styles/main/profile";
import Text from "../../../core/utils/Text";
import Operation from "../../common/utils/Operation";
import Avatar from "../../common/utils/Avatar";
import useUsersList from "../../../core/hooks/main/users/useUsersList";
import useGetTableIndex from "../../../core/hooks/layout/useGetTableIndex";
import { acceptUserOperations, rejectUserOperations } from "../../../core/constants/operation";
import { userAttributes } from "../../../core/constants/headers";
import { statusColors } from "../../../core/utils/theme";


const UsersTable = ({
    data
}) => {

    const { data: users } = data
    const { cs, headers } = userAttributes
    const { getTableIndex } = useGetTableIndex()

    const {
        hasWriteAccess,
        onOptionClicked,
        onDetailsClicked
    } = useUsersList()

    return (
        <TableLayout
            headers={headers}
            cs={cs}
            data={data}
            hasWriteAccess={hasWriteAccess}
        >
            {users?.data?.list?.map((user, idx) => {

                return (
                    <Relative>
                        <Row cs={cs} index={idx} key={user._id}>
                            <Column>
                                {getTableIndex(idx)}
                            </Column>
                            <Column avatar>
                                <Flex width='42px'>
                                    <Avatar
                                        name={user.full_name.length > 1 ? user.full_name : 'U'}
                                        avatar={user.personal_photo}
                                    />
                                </Flex>

                                <DText fontSize={'s'} main>
                                    {formatName(user.full_name.length > 1 ? user.full_name : '--')}
                                </DText>
                            </Column>
                            <Column>
                                {user.passport_number || '--'}
                            </Column>
                            <Column>
                                {user.mobile_number || '--'}
                            </Column>
                            <Column>
                                {formatNumber(user.wallet_balance)}
                            </Column>
                            <Column>
                                {user.email || '--'}
                            </Column>
                            <Column>
                                <UserStatus color={statusColors[user.is_kyc_passed]}>
                                    <Text tid={user.is_kyc_passed} />
                                </UserStatus>
                            </Column>
                            <Column>
                                <UserStatus color={statusColors[user.is_agent_status]}>
                                    <Text tid={user.is_agent_status} />
                                </UserStatus>
                            </Column>
                        </Row>
                        <Column operation>
                            <Operation
                                options={user.verifyAt ? rejectUserOperations : acceptUserOperations}
                                onOptionClicked={(idx) => onOptionClicked(idx, user)}
                                onDetailsClicked={() => onDetailsClicked(user)}
                                hasWriteAccess={hasWriteAccess}
                                hasDetails
                            />
                        </Column>
                    </Relative>

                )
            })}
        </TableLayout>
    )
}



export default UsersTable
