import ProfileInfo from "../profile/ProfileInfo";
import ProfileBanks from "../profile/ProfileBanks";
import { Flex } from "../../../styles/CommonStyles";
import ProfileWallets from "../profile/ProfileWallets";
import UserBalances from "./elements/UserBalances";
import { useParams } from "react-router-dom";
import { useGetAUser } from "../../../core/services/react-query/user";
import { useEffect } from "react";
import UserCurrentOrders from "./elements/UserCurrentOrders";
import UserTrades from "./elements/UserTrades";
import UserOtcTrades from "./elements/UserOtcTrades";
import UserCoinTransactions from "./elements/UserCoinTransactions";
import UserBankTransactions from "./elements/UserBankTransactions";
import UserActivities from "./elements/activity/UserActivities";
import UserSetting from "./elements/setting/UserSetting";
import UserAffiliate from "./elements/UserAffiliate";
import UserTrace from "./elements/UserTrace";



const UserSubpages = ({
    activeTab
}) => {


    const subpages = [
        ProfileInfo,
        UserBalances,
        // ProfileBanks,
        // ProfileWallets,
        // UserCurrentOrders,
        // UserTrades,
        // UserOtcTrades,
        // UserCoinTransactions,
        // UserBankTransactions,
        // UserActivities,
        // UserAffiliate,
        // UserTrace,
        UserSetting
    ]
    const Subpage = subpages[activeTab]

    return (
        <Flex fw align={'flex-start'} style={{ overflowX: 'auto' }}>
            <Subpage
                detail
            />
        </Flex>
    )
}


export default UserSubpages
